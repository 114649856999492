export default {
  // 销售额/订单量/客单价
  async getSaleAmountAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/orderAmont.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSaleAmountReportAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis//orderAmontList.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 热销商品
  async getHotProductsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/hotGoods.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 转化率
  async getCloseRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/analysis.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 浏览量
  async getPageViewsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/viewPage.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 访客数
  async getUserViewsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/viewUserNums.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 访客数报告
  async getUserViewsReportAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/viewUserNumsList.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 访客国家和地区
  async getVisitsByCountryAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/viewAddress.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 访客设备
  async getVisitsByDevicesAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/viewDevice.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 热门登录页
  async getHotPagesAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/hotPage.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 商品详情页统计
  async getProductReviewsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Analysis/viewGoods.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
