export default {
  marketingByOrder: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
  marketingByGoods: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
  marketingCoupons: {
    status: 0,
    data: [],
  },
};
