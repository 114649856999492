import * as R from 'ramda';

export default {
  async addMarketingRuleByOrderAsync(context, payload) {
    try {
      const res = await this.$axios.post('/m/orderPromationAdd.html', payload);
      if (res.data.status === 1) {
        // await context.dispatch('getMarketingByOrderAsync', { timeLimit: null });
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMarketingByOrderAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/m/orderPromationList.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.marketingByOrder.data.timeLimit,
            p: context.state.marketingByOrder.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_MARKETING_BY_ORDER', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addMarketingRuleByGoodsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/m/goodPromationAdd.html', payload);
      if (res.data.status === 1) {
        // await context.dispatch('getMarketingByGoodsAsync', { timeLimit: null });
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMarketingByGoodsAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/m/goodPromationList.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.marketingByGoods.data.timeLimit,
            p: context.state.marketingByGoods.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_MARKETING_BY_GOODS', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async nullifyGoodsRecordAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 1303 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/m/goodPromationInvalid.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getMarketingByGoodsAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit(
        'TOGGLE_SNACKBAR',
        {
          type: 'error',
          text: '您没有该操作的权限，请联系管理员',
        },
        { root: true },
      );
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async nullifyOrderRecordAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 1307 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/m/orderPromationInvalid.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getMarketingByOrderAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit(
        'TOGGLE_SNACKBAR',
        {
          type: 'error',
          text: '您没有该操作的权限，请联系管理员',
        },
        { root: true },
      );
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async getMarketingByGoodsDetailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/m/goodPromationDetail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMarketingByOrderDetailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/m/orderPromationDetail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置订单免邮额度
   * @date 2022-08-04
   * @param context
   * @param payload
   * @returns {*}
   * @arguments fullMailFree
   */
  async setMarketingFreeShippingAsync(context, payload) {
    try {
      const res = await this.$axios.post('/m/setFullMailFree.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取优惠券列表
   * @date 2022-06-09
   * @param context
   * @param payload
   * @returns {
   * 字段：
    periodType ：过期时间  0：永久有效  1：1个月  2:2个月 3:3个月  4:6个月  5:1年
    receiveType： 领取类型 1: 自动发放（新人券）  2：手动领取
    type 优惠券类型：1：满减券  3：打折券
    fullReduction  满减金额 当type 为1时，生效
    dStatus 1：启用 2：不启用
    sendType  1：会员获得 2：订单  3：任务（签到） 4：其他
    }
   */
  async getMarketingCouponAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Coupon/lists.html', {
        ...payload,
      });
      if (res.data.status === 1) {
        context.commit('SET_MARKETING_COUPONS', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 添加优惠券
   * @date 2022-06-09
   * @param context
   * @param payload
   * @returns {*}
   */
  async addMarketingCouponAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Coupon/setSellerCoupon.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 编辑优惠券
   * @date 2022-06-09
   * @param context
   * @param payload
   * @returns {*}
   */
  async editMarketingCouponAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Coupon/editSellerCoupon.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 启用/禁用优惠券
   * @date 2022-06-09
   * @param context
   * @param payload
   * @returns {*}
   */
  async toggleMarketingCouponAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Coupon/setCouponStatus.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 删除未启用的优惠券
   * @date 2022-06-09
   * @param context
   *  id  operate【1:开启  0：关闭】
   * @param payload
   * @returns {*}
   */
  async deleteMarketingCouponAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Coupon/deleteCoupon.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
