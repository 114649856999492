// root
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const SET_GLOBAL_CUSTOMER = 'SET_GLOBAL_CUSTOMER';
export const SET_DIALOG_OFF = 'SET_DIALOG_OFF';
export const SET_ALL_COUNTRY = 'SET_ALL_COUNTRY';

// product
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_PRODUCT_LIST_FOR_SELECT = 'SET_PRODUCT_LIST_FOR_SELECT';
export const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
export const SET_PRODUCT_BRAND = 'SET_PRODUCT_BRAND';
export const SET_PRODUCT_UNITS = 'SET_PRODUCT_UNITS';
export const SET_ONLINE_CATE = 'SET_ONLINE_CATE';
export const SET_PRODUCT_MODELS = 'SET_PRODUCT_MODELS';
export const SET_PRODUCT_DETAIL_MODELS = 'SET_PRODUCT_DETAIL_MODELS';
export const SET_PRODUCT_HOT_SEARCH = 'SET_PRODUCT_HOT_SEARCH';
export const SET_DRAFT_LIST = 'SET_DRAFT_LIST';

// supplier
export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST';

// customer
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const SET_CUSTOMER_FOR_SEARCH_LIST = 'SET_CUSTOMER_FOR_SEARCH_LIST';
export const SET_CUSTOMER_FOR_SELECT_LIST = 'SET_CUSTOMER_FOR_SELECT_LIST';
export const SET_CUSTOMER_GRADE_LIST = 'SET_CUSTOMER_GRADE_LIST';
export const SET_CUSTOMER_NEWS_LETTER_LIST = 'SET_CUSTOMER_NEWS_LETTER_LIST';
export const SET_CUSTOMER_FEEDBACK_LIST = 'SET_CUSTOMER_FEEDBACK_LIST';

// system
export const SET_EMPLOYEE_LIST = 'SET_EMPLOYEE_LIST';
export const SET_BASIC_INFO = 'SET_BASIC_INFO';
export const SET_AREA_INFO = 'SET_AREA_INFO';
export const SET_ORDER_SEQUENCE = 'SET_ORDER_SEQUENCE';
export const CLEAR_BASIC_INFO = 'CLEAR_BASIC_INFO';
export const SET_SYSTEM_TEMPLATE = 'SET_SYSTEM_TEMPLATE';
export const SET_DELIVERY_LIST = 'SET_DELIVERY_LIST';
export const SET_ALL_CURRENCY = 'SET_ALL_CURRENCY';
export const SET_MAIL_OPTION = 'SET_MAIL_OPTION';
export const SET_MAIL_LIST = 'SET_MAIL_LIST';
export const SET_HOSTS = 'SET_HOSTS';

// finance
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_BANK_LIST = 'SET_BANK_LIST';
export const SET_PAYMENT_ORDER_LIST = 'SET_PAYMENT_ORDER_LIST';

// order
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_RETURN_ORDER_LIST = 'SET_RETURN_ORDER_LIST';

// authority
export const SET_DEPARTMENT_LIST = 'SET_DEPARTMENT_LIST';
export const SET_AUTHORITY_LIST = 'SET_AUTHORITY_LIST';
export const SET_AUTHORITY_ROLE_LIST = 'SET_AUTHORITY_ROLE_LIST';

// warehouse
export const SET_WAREHOUSE_LIST = 'SET_WAREHOUSE_LIST';
export const SET_WAREHOUSE_INSTOCK_LIST = 'SET_WAREHOUSE_INSTOCK_LIST';
export const SET_WAREHOUSE_OUTSTOCK_LIST = 'SET_WAREHOUSE_OUTSTOCK_LIST';
export const SET_WAREHOUSE_INSTOCK_TYPE_LIST = 'SET_WAREHOUSE_INSTOCK_TYPE_LIST';
export const SET_WAREHOUSE_OUTSTOCK_TYPE_LIST = 'SET_WAREHOUSE_OUTSTOCK_TYPE_LIST';
export const SET_WAREHOUSE_PRODUCT_STOCK = 'SET_WAREHOUSE_PRODUCT_STOCK';

// information
export const SET_INFO_CATEGORIES = 'SET_INFO_CATEGORIES';
export const SET_INFO_LIST = 'SET_INFO_LIST';
export const SET_NEWS_LIST = 'SET_NEWS_LIST';

// app
export const SET_APP_LIST = 'SET_APP_LIST';
export const SET_APP_INSTALLED = 'SET_APP_INSTALLED';

// marketing
export const SET_MARKETING_BY_ORDER = 'SET_MARKETING_BY_ORDER';
export const SET_MARKETING_BY_GOODS = 'SET_MARKETING_BY_GOODS';
export const SET_MARKETING_COUPONS = 'SET_MARKETING_COUPONS';
