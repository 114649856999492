export default {
  infoCategories: {
    status: 0,
    data: [],
  },
  infoList: {
    status: 0,
    data: [],
  },
  newsList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
};
