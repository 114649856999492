import {
  SET_MARKETING_BY_ORDER,
  SET_MARKETING_BY_GOODS,
  SET_MARKETING_COUPONS,
} from '../mutation-types';

export default {
  [SET_MARKETING_BY_ORDER](state, payload) {
    state.marketingByOrder = payload;
  },
  [SET_MARKETING_BY_GOODS](state, payload) {
    state.marketingByGoods = payload;
  },
  [SET_MARKETING_COUPONS](state, payload) {
    state.marketingCoupons.data = payload;
    state.status = 1;
  },
};
