export default {
  productListForSelect: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: 100,
      p: 1,
    },
  },
  orderList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: 100,
      p: 1,
    },
  },
  returnOrderList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: 100,
      p: 1,
    },
  },
};
