export default {
  appList: {
    status: 0,
    data: {
      items: [],
      myApp: [],
    },
  },
  appInstalled: {
    status: 0,
    data: [],
  },
  emailList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
};
