import moment from 'moment';
import * as R from 'ramda';

const mapIndexed = R.addIndex(R.map);

moment.locale('zh-cn', {
  months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
  monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
  weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'MM-DD',
    LLL: 'YY-MM-DD',
    LLLL: 'YYYY-MM-DD HH:mm:ss',
    l: 'YYYY-M-D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm',
  },
});

// const spreadByNum = (type, start, num) => {
//   const arr = [];
//   if (type === '1') {
//     let n = moment(start).hour();
//     // hours
//     while (n <= 24) {
//       arr.push(moment().hour(n).minute(0).second(0)
//         .format('LLLL'));
//       n += num;
//     }
//   } else if (type === '2') {
//     let n = moment(start).hour();
//     // days
//     while (n < 24) {
//       arr.push(moment().day(n).format('HH:MM'));
//       n += num;
//     }
//   } else {
//     let n = moment(start).hour();
//     // month
//     while (n < 24) {
//       arr.push(moment().month(n).format('HH:MM'));
//       n += num;
//     }
//   }
//   return arr;
// };

const weekDaysLocal = R.concat(R.tail(moment.weekdaysShort()), R.take(1, moment.weekdaysShort()));

const today = moment().format('L');
const todayStart = moment().hour(0).minute(0).second(0)
  .format('LLLL');
const yesterdayStart = moment().subtract(1).hour(0).minute(0)
  .second(0)
  .format('LLLL');
const tomorrowStart = moment().add(1, 'days').hour(0).minute(0)
  .second(0)
  .format('LLLL');
const tomorrow = moment().add(1, 'days').format('L');
const yesterday = moment().subtract(1, 'days').format('L');
const thisMonday = moment().weekday(1).format('L');
const lastMonday = moment().weekday(-6).format('L');
const lastSunday = moment().weekday(0).format('L');
const firstDayThisMonth = moment().startOf('month').format('L');
const firstDayLastMonth = moment().subtract(1, 'month').startOf('month').format('L');
const lastDayLastMonth = moment().subtract(1, 'month').endOf('month').format('L');
const firstDayThisYear = moment().startOf('year').format('L');
const firstDayLastYear = moment().subtract(1, 'years').startOf('year').format('L');
const lastDayLastYear = moment().subtract(1, 'years').endOf('year').format('L');
const weekDaysFramework = mapIndexed(
  (item, index) => ({
    date: item,
    allAmount: 0,
    allCount: 0,
    index,
  }),
  weekDaysLocal,
);
const yearFramework = mapIndexed(
  (item, index) => ({
    date: item,
    allAmount: 0,
    allCount: 0,
    index,
  }),
  moment.monthsShort(),
);
const thisMonthDays = moment().daysInMonth();
const lastMonthDays = moment().subtract(1, 'month').daysInMonth();
const getDayArrayByMonthLength = (length) => {
  const arr = [];
  for (let i = 1; i <= length; i += 1) {
    arr.push(`${i}日`);
  }
  return arr;
};
const monthFrameWorkThisMonth = mapIndexed(
  (item, index) => ({
    date: item,
    allAmount: 0,
    allCount: 0,
    index,
  }),
  getDayArrayByMonthLength(thisMonthDays),
);
const monthFrameWorkLastMonth = mapIndexed(
  (item, index) => ({
    date: item,
    allAmount: 0,
    allCount: 0,
    index,
  }),
  getDayArrayByMonthLength(lastMonthDays),
);

export {
  moment,
  today,
  tomorrow,
  yesterday,
  thisMonday,
  lastMonday,
  lastSunday,
  firstDayThisMonth,
  firstDayLastMonth,
  lastDayLastMonth,
  firstDayThisYear,
  firstDayLastYear,
  lastDayLastYear,
  weekDaysFramework,
  yearFramework,
  monthFrameWorkThisMonth,
  monthFrameWorkLastMonth,
  // spreadByNum,
  todayStart,
  yesterdayStart,
  tomorrowStart,
  weekDaysLocal,
};
