import axios from 'axios';

// 响应时间
axios.defaults.timeout = 20000;
// 配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 配置接口地址
axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.withCredentials = true;
// 每次请求携带cookie信息
// // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

export default axios;
