import { moment } from '@/plugins/moment';
import * as R from 'ramda';
import {
  SET_APP_LIST,
  SET_MAIL_OPTION,
  SET_APP_INSTALLED,
} from '../mutation-types';

export default {
  [SET_APP_LIST](state, payload) {
    state.appList = payload;
  },
  [SET_APP_INSTALLED](state, payload) {
    const data = R.map((item) => {
      item.isDisabled = item.periodTime && moment().isAfter(moment(item.periodTime));
      item.isOverdue = item.reType === '2' && !item.nums;
      item.isPeriod = item.periodTime && moment().isBefore(moment(item.periodTime));
      return item;
    }, payload);
    state.appInstalled = {
      status: 1,
      data,
    };
  },
  [SET_MAIL_OPTION](state, payload) {
    state.emailList = payload;
  },
};
