<template>
  <div id="app">
    <v-app id="inspire">
      <router-view />
      <v-snackbar
        :value="snackbarShow"
        :color="snackbarColor"
        :timeout="3000"
        top
        @input="toggleSnackbar"
      >
        {{ snackbarText }}
        <template v-slot:action="{ }">
          <v-icon
            dark
          >
            {{ snackbarIcon }}
          </v-icon>
        </template>
      </v-snackbar>
      <v-dialog
        v-model="dialogOff"
        max-width="500px"
        persistent
        no-click-animation
      >
        <v-card>
          <v-card-title class="pr-2">
            <v-spacer />
            <v-btn
              color="grey"
              icon
              @click="redirectToLogin"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-body-1 black--text d-flex align-center">
            <v-icon
              color="primary"
              class="mr-2"
            >
              mdi-information-outline
            </v-icon> 您的登录已过期，请重新登录
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="redirectToLogin"
            >
              确定
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        v-show="$store.state.loading"
        style="position: absolute;top:0;right:0;left:0;z-index:100"
      >
        <v-progress-linear
          indeterminate
          height="5"
          striped
          color="yellow accent-2"
        />
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState([
      'snackbarColor',
      'snackbarShow',
      'snackbarText',
      'snackbarIcon',
      'loading',
      'dialogOff',
    ]),
  },
  // watch: {
  //   snackbarShow() {
  //     if (this.snackbarShow) {
  //       setTimeout(() => this.$store.commit('TOGGLE_SNACKBAR', {}), 3000);
  //     }
  //   },
  // },
  // mounted() {
  //   this.onResize();

  //   window.addEventListener('resize', this.onResize, { passive: true });
  // },
  methods: {
    toggleSnackbar(v) {
      if (!v) {
        this.$store.commit('CLOSE_SNACKBAR');
      }
    },
    redirectToLogin() {
      this.$store.commit('SET_DIALOG_OFF', false);
      if (this.$route.name !== 'login') {
        this.$router.replace({ name: 'login' });
      }
    },
    // onResize() {
    //   this.snackbar = this.$vuetify.breakpoint.mobile;
    // },
  },
};
</script>
