import * as R from 'ramda';
import {
  SET_EMPLOYEE_LIST,
  SET_BASIC_INFO,
  SET_AREA_INFO,
  SET_ORDER_SEQUENCE,
  // CLEAR_BASIC_INFO,
  SET_SYSTEM_TEMPLATE,
  SET_ALL_COUNTRY,
  SET_DELIVERY_LIST,
  SET_ALL_CURRENCY,
  SET_HOSTS,
  SET_MAIL_OPTION,
} from '../mutation-types';

export default {
  // [CLEAR_BASIC_INFO](state) {
  //   state.basicInfo.status = 0;
  // },
  DISABLE_INIT_REGION_FEE(state) {
    state.basicInfo.data.isCopyDeliver = '1';
  },
  [SET_SYSTEM_TEMPLATE](state, payload) {
    state.templates.status = 1;
    state.templates.data = payload;
  },
  [SET_HOSTS](state, payload) {
    state.hosts.status = 1;
    state.hosts.data = payload;
  },
  [SET_MAIL_OPTION](state, payload) {
    state.mailOptions.status = 1;
    state.mailOptions.data = payload;
  },
  [SET_ALL_CURRENCY](state, payload) {
    state.allCurrency.status = 1;
    state.allCurrency.data = payload;
  },
  [SET_EMPLOYEE_LIST](state, payload) {
    state.employeeList = payload;
  },
  [SET_BASIC_INFO](state, payload) {
    const basicInfo = R.head(payload.data);
    if (!basicInfo.addressId || basicInfo.addressId === '0') {
      basicInfo.addressId = '15860';
    }
    if (!basicInfo.addressFirstId || basicInfo.addressFirstId === '0') {
      basicInfo.addressFirstId = '1';
    }
    if (!basicInfo.addressSecondId || basicInfo.addressSecondId === '0') {
      basicInfo.addressSecondId = '40255';
    }
    if (!basicInfo.logo) {
      basicInfo.logo = '';
    }
    if (!basicInfo.image) {
      basicInfo.image = '';
    }
    state.basicInfo = {
      status: 1,
      data: basicInfo,
    };
  },
  [SET_AREA_INFO](state, payload) {
    state.areaInfo = payload;
  },
  [SET_ORDER_SEQUENCE](state, payload) {
    if (payload.data.length) {
      state.orderSequence.data = R.prepend(state.orderSequence.data[0], payload.data);
    }
    state.orderSequence.status = payload.status;
  },
  [SET_ALL_COUNTRY](state, payload) {
    state.countryList.data = R.filter(R.prop('region'), payload.data);
    state.countryList.hasSetData = payload.hasSetData;
    state.countryList.status = 1;
  },
  [SET_DELIVERY_LIST](state, payload) {
    state.deliveryList = payload;
  },
};
