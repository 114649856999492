import * as R from 'ramda';

export default {
  // async getInfoCategoriesAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/i/categoryLists.html', payload);
  //     if (res.data.status === 1) {
  //       context.commit('SET_INFO_CATEGORIES', res.data);
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async addInfoCategoryAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/i/categoryAdd.html', payload);
  //     if (res.data.status === 1) {
  //       await context.dispatch('getInfoCategoriesAsync');
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async editInfoCategoryAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/i/categoryEdit.html', payload);
  //     if (res.data.status === 1) {
  //       await context.dispatch('getInfoCategoriesAsync');
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async deleteInfoCategoryAsync(context, payload) {
  //   const right = await context.dispatch('checkAuthorityAsync', { rightId: 7014 }, { root: true });
  //   if (right) {
  //     try {
  //       const res = await this.$axios.post('/i/categoryDelete.html', payload);
  //       if (res.data.status === 1) {
  //         await context.dispatch('getInfoCategoriesAsync');
  //         return Promise.resolve(res.data.status);
  //       }
  //       return Promise.reject(new Error(res.data.info));
  //     } catch (error) {
  //       return Promise.reject(error);
  //     }
  //   } else {
  //     context.commit('TOGGLE_SNACKBAR', {
  //       type: 'error',
  //       text: '您没有该操作的权限，请联系管理员',
  //     }, { root: true });
  //     return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
  //   }
  // },
  // async getInfoListAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/i/lists.html', payload);
  //     if (res.data.status === 1) {
  //       context.commit('SET_INFO_LIST', res.data);
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async addInfoAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/i/add.html', payload);
  //     if (res.data.status === 1) {
  //       await context.dispatch('getInfoListAsync');
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async editInfoAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/i/edit.html', payload);
  //     if (res.data.status === 1) {
  //       await context.dispatch('getInfoListAsync');
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async deleteInfoAsync(context, payload) {
  //   const right = await context.dispatch('checkAuthorityAsync', { rightId: 7004 }, { root: true });
  //   if (right) {
  //     try {
  //       const res = await this.$axios.post('/i/delete.html', payload);
  //       if (res.data.status === 1) {
  //         await context.dispatch('getInfoListAsync');
  //         return Promise.resolve(res.data.status);
  //       }
  //       return Promise.reject(new Error(res.data.info));
  //     } catch (error) {
  //       return Promise.reject(error);
  //     }
  //   } else {
  //     context.commit('TOGGLE_SNACKBAR', {
  //       type: 'error',
  //       text: '您没有该操作的权限，请联系管理员',
  //     }, { root: true });
  //     return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
  //   }
  // },
  async getNewsListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/n/lists.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.newsList.data.timeLimit,
            p: context.state.newsList.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_NEWS_LIST', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getNewsDetailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/n/detail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addNewsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/n/add.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getNewsListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editNewsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/n/edit.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getNewsListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteNewsAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 7024 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/n/delete.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('getNewsListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
};
