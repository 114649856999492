import Vue from 'vue';
import VueRouter from 'vue-router';
import { retailAndWholesale, setRetailAndWholesaleMenu } from '@/router/retailAndWholesale';
import { retail, setRetailMenu } from '@/router/retail';
import { wholesale, setWholesaleMenu } from '@/router/wholesale';
import basicRoutes from '@/router/basic';
import store from '../store';

Vue.use(VueRouter);

export default (type, appIds = []) => {
  let routes = basicRoutes;
  let menus = [];

  if (type === '1' || !type) {
    routes = [...basicRoutes, ...retail];
    menus = setRetailMenu(appIds);
  } else if (type === '4') {
    routes = [...basicRoutes, ...retailAndWholesale];
    menus = setRetailAndWholesaleMenu(appIds, store.state.system.basicInfo.data.template === 'chicos2');
  } else {
    routes = [...basicRoutes, ...wholesale];
    menus = setWholesaleMenu(appIds);
  }
  store.commit('SET_MENUS', menus);

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });
};
