import * as R from 'ramda';

export default {
  // 上传Excel
  async uploadExcelSync(context, payload) {
    try {
      const res = await this.$axios({
        url: '/g/export_execl.html',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: payload,
      });
      if (res.data.status === 1) {
        return Promise.resolve(res.data.info);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 在线获取
  async getOnlineInfoAsync(context, payload) {
    try {
      const res = await this.$axios.post('/py/getGoodsInfo.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 商品分类
  async getCateListAsync(context) {
    // if (context.state.productCategory.status) return 1;
    try {
      const res = await this.$axios.post('/cate/cate_lists.html');
      if (res.data.status === 1) {
        context.commit('SET_PRODUCT_CATEGORY', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addCateAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 20 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/do_category_add.html', payload);
        if (res.data.status === 1) {
          context.commit('SET_PRODUCT_CATEGORY', res.data.data);
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async editCateAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 21 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/do_category_edit.html', payload);
        if (res.data.status === 1) {
          context.commit('SET_PRODUCT_CATEGORY', res.data.data);
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async deleteCateAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 22 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/del_category.html', payload);
        if (res.data.status === 1) {
          context.commit('SET_PRODUCT_CATEGORY', res.data.data);
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 商品分类规格
  async getCateSpecListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/cate/category_spec_list.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addCateSpecAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 50 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/do_category_spec_add.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async editCateSpecAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 51 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/do_category_spec_edit.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async deleteCateSpecAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 52 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/del_category_spec.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 商品分类属性
  async getCateAttrListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/cate/category_attr_list.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addCateAttrAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 35 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/do_category_attr_add.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async editCateAttrAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 36 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/do_category_attr_edit.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async deleteCateAttrAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 37 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/cate/del_category_attr.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 获取产品分类属性和规格
  async getCateAttrSpecAsync(context, payload) {
    try {
      const res = await this.$axios.post('/cate/category_attr_spec_list.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 商品品牌
  async addOrEditBrandAsync(context, payload) {
    const right = await context.dispatch(
      'checkAuthorityAsync',
      { rightId: payload.edit ? 358 : 357 },
      { root: true },
    );
    if (right) {
      try {
        const res = await this.$axios.post('/brand/add_save_brand.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getBrandListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async deleteBrandAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 359 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/brand/del_brand.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getBrandListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async getBrandListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/brand/brand_lists.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_PRODUCT_BRAND', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 商品单位
  async addOrEditUnitsAsync(context, payload) {
    const right = await context.dispatch(
      'checkAuthorityAsync',
      { rightId: payload.edit ? 66 : 65 },
      { root: true },
    );
    if (right) {
      try {
        const res = await this.$axios.post('/unit/add_save.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('getUnitsListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async deleteUnitsAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 67 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/unit/del_unit.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('getUnitsListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async getUnitsListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/unit/unit_lists.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_PRODUCT_UNITS', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 选择商品
  async getProductListForSelectAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/g/lists.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.productListForSelect.data.timeLimit,
            p: context.state.productListForSelect.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_PRODUCT_LIST_FOR_SELECT', res.data.data);
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 添加商品
  async addProductAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/add.html', payload);
      if (res.data.status === 1) {
        // await context.dispatch('getProductListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 删除商品
  async deleteProductAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 302 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/g/delete.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getProductListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 上下架商品
  async operateProductAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 305 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/g/setVisible.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getProductListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 商品列表
  async getProductListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/g/lists.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.productList.data.timeLimit,
            p: context.state.productList.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_PRODUCT_LIST', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getProductForSelectAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/lists.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 商品修改详情
  async getProductDetailEditAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/detailEdit.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 商品详情
  async getProductDetailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/detail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 编辑商品
  async editProductAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/edit.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 编辑商品标签
  async setProductLabelAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 306 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/g/setLabel.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getProductListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 上传草稿商品excel
  async uploadExcelDraftAsync(context, payload) {
    try {
      const res = await this.$axios(
        {
          url: 'ig/imageExecl.html',
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: payload,
        },
      );
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 草稿列表
  async getDraftListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        'ig/draftLists.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.draftList.data.timeLimit,
            p: context.state.draftList.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_DRAFT_LIST', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 草稿详情
  async getDraftDetailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/ig/detail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 删除草稿
  async deleteDraftAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 103 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/ig/delete.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getDraftListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 获取商品评论
  async getProductCommentsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Remark/lists.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async operateProductCommentsAsync(context, payload) {
    // id  operate   1:显示  2：不显示
    try {
      const res = await this.$axios.post('/Remark/setRemarkVisible.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 尺码详情
  async getProductSizeChartAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/getSeoPolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 设置尺码表
  async setProductSizeChartAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setSizeInfo.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置商品销量
   * @date 2022-18-02
   * @param context
   * @param payload
   * @returns status
   * @arguments id saleNum
   */
  async setProductSaleNumAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/setSaleNum.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置商品置顶
   * @date 2022-29-04
   * @param context
   * @param payload
   * @returns {*}
   * @参数 id
   */
  async setProductStickAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/setStick.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 取消商品置顶
   * @date 2022-29-04
   * @param context
   * @param payload
   * @returns {*}
   * @参数 id
   */
  async setProductUnstickAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/unSetStick.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置商品浏览量
   * @date 2022-18-02
   * @param context
   * @param payload
   * @returns status
   * @arguments id viewNum
   */
  async setProductViewNumAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/setViewNum.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 生成评论
   * @date 2022-19-02
   * @param context
   * @param payload
   * @returns {*}
   * @arguments
   *  nums 次数  【必填】
      countrys 国家名称 数组【必填】
      goodId  商品id【必填】
      detailIds  明细id 数组【必填】
      buyerNames  客户名称 数组【必填】
      content 评论内容 数组【必填】
      minXingji 最小星级  数字
      maxXingji 最大星级
      beginDate 刷单开始时间
      endDate  刷单结束时间
      images 图片  数组
   */
  async generateCommentsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Remark/brushComments.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 修改评论
   * @date 2022-19-02
   * @param context
   * @param payload
   * @returns {*}
   * @arguments
   * id
   * xingJi
     content
     images
   */
  async editCommentAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Remark/editRemark.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 删除评论
   * @date 2022-19-02
   * @param context
   * @param payload
   * @returns {*}
   * @arguments
   * id
   */
  async deleteCommentAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Remark/deleteRemark.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取系统评论
   * @date 2022-19-02
   * @param context
   * @param payload
   * @returns {*}
   */
  async getSystemCommentContentAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Remark/getContent.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_SYSTEM_COMMENT_CONTENT', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取系统评论客户
   * @date 2022-19-02
   * @param context
   * @param payload
   * @returns {*}
   */
  async getSystemCommentUserAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Remark/getUser.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_SYSTEM_COMMENT_USER', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置关联商品
   * @date 2022-04-03
   * @param context
   * @param payload
   * @returns {*}
   * @arguments {id, detail: [{relationGoodId, goodId, goodName, goodImage, goodNo, goodPrice, sellerId}]}
   */
  async setProductRelationAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/adRelationInfo.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取已关联商品
   * @date 2022-04-03
   * @param context
   * @param payload
   * @returns {*}
   * @arguments id
   */
  async getProductRelationAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/getRelationInfo.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设为跨境商品
   * @date 2022-08-03
   * @param context
   * @param payload
   * @returns {*}
   * @arguments id, price
   */
  async setProductKuaJingAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/liveGoods.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 加解密商品
   * @date 2023-08-02
   * @param context 商品id数组 goodsId 必传 type 1：加密 2：解密 必传 password 非必传
   * @param payload
   * @returns {*}
   */
  async setProductEncryptAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/batchSetGoodsPassword.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取跨境商品价格
   * @date 2022-08-03
   * @param context
   * @param payload
   * @returns {*}
   * @arguments id
   */
  // async getProductKuaJingPriceAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/g/getWholsePrice.html', payload);
  //     if (res.data.status === 1) {
  //       return Promise.resolve(res.data.price);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
};
