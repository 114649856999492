import * as R from 'ramda';

export default {
  // 添加编辑员工，设置员工启停用，修改密码
  async addOrEditEmployeeAsync(context, payload) {
    let rightId;
    if (payload.stop) {
      rightId = 246;
    } else if (payload.edit) {
      rightId = 244;
    } else {
      rightId = 243;
    }
    const right = await context.dispatch('checkAuthorityAsync', { rightId }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/a/do_admin.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('getEmployeeListAsync', { timeLimit: null });
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 员工列表
  async getEmployeeListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/a/fetchs.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.employeeList.data.timeLimit,
            p: context.state.employeeList.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        if (payload && payload.all === '1') {
          return Promise.resolve(res.data.data.items);
        }
        context.commit('SET_EMPLOYEE_LIST', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getEmployeeSingleAsync(context, payload) {
    try {
      const res = await this.$axios.post('/a/detail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSystemHostsAsync(context, payload) {
    try {
      const res = await this.$axios.post('sy/getUserHosts', payload);
      if (res.data.status === 1) {
        context.commit('SET_HOSTS', res.data.data || []);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addSystemHostsAsync(context, payload) {
    try {
      const res = await this.$axios.post('sy/addUserHosts', payload);
      if (res.data.status === 1) {
        await context.dispatch('getSystemHostsAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editSystemHostsAsync(context, payload) {
    try {
      const res = await this.$axios.post('sy/editUserHosts', payload);
      if (res.data.status === 1) {
        await context.dispatch('getSystemHostsAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteSystemHostsAsync(context, payload) {
    try {
      const res = await this.$axios.post('sy/deleteUserHosts', payload);
      if (res.data.status === 1) {
        await context.dispatch('getSystemHostsAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 订单处理流程列表
  async orderSequenceListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/system/order_flow_setting_list.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_ORDER_SEQUENCE', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 订单处理流程设置
  async addOrEditOrderSequenceAsync(context, payload) {
    const right = await context.dispatch(
      'checkAuthorityAsync',
      { rightId: payload.edit ? 72 : 71 },
      { root: true },
    );
    if (right) {
      try {
        const res = await this.$axios.post('/system/add_save_order_flow_setting.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('orderSequenceListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 禁用订单处理流程
  async disableOrderSequenceAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 73 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/system/del_order_flow_setting.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('orderSequenceListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 基本信息
  async getBasicInfoAsync(context, payload) {
    try {
      const res = await this.$axios.post('/system/sys_info_list.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_BASIC_INFO', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addOrEditBasicInfoAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 74 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/system/add_save_sys_info.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('getBasicInfoAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  async editMenuSetupAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/set_navigation.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getBasicInfoAsync');
        return Promise.resolve(res.data.info);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editTemplateSetupAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/save_template.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getBasicInfoAsync');
        return Promise.resolve(res.data.info);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setTemplateAdConfigAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/set_ad_config.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.info);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSystemTemplateAsync(context, payload) {
    if (context.state.templates.status) return Promise.resolve(1);
    try {
      const res = await this.$axios.post('/sy/template_lists.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_SYSTEM_TEMPLATE', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSystemTemplateDetailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/template_detail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAreaInfoAsync(context, payload) {
    if (sessionStorage.getItem('areaInfo')) {
      context.commit('SET_AREA_INFO', JSON.parse(sessionStorage.getItem('areaInfo')));
      return Promise.resolve(1);
    }
    try {
      const res = await this.$axios.post('/system/getFirstArea.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_AREA_INFO', res.data);
        sessionStorage.setItem('areaInfo', JSON.stringify(res.data));
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAreaRegionInfoAsync(context, payload) {
    try {
      const res = await this.$axios.post('/system/getNextArea.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 获取广告图
  async getAdsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/ads/banners_ads_lists.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 上传广告图
  async setAdsAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 501 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/ads/do_banners_ads.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 获取其他广告图
  async getOtherAdsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/ads/other_ads_lists.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 上传其他广告图
  async setOtherAdsAsync(context, payload) {
    console.log('setOtherAdsAsync - payload:', payload);
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 503 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/ads/do_other_ads.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 设置折扣方式
  async setPriceStatusAsync(context, payload) {
    try {
      const res = await this.$axios.post('/us/setDefaulPriceStyle.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 国家列表
  async getAllCountryAsync(context, payload) {
    try {
      const res = await this.$axios('/sy/getCountry.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_ALL_COUNTRY', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setAllCountryAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setFreeShippingRegion.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getAllCountryAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 发货与运费
  async setDeleveryMethod(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/setShipping.html', payload);
      if (res.data.status === 1) {
        const { basicInfo } = context.state;
        basicInfo.data.isFreeShipping = payload.isFreeShipping;
        basicInfo.data = [basicInfo.data];
        context.commit('SET_BASIC_INFO', basicInfo);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getDeliveryListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/lists.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_DELIVERY_LIST', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addDeliveryRegionAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/add.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getDeliveryListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editDeliveryRegionAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/edit.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getDeliveryListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteDeliveryRegionAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/delete.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getDeliveryListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addRegionChargeAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/expressAdd.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getDeliveryListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editRegionChargeAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/expressEdit.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getDeliveryListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteRegionChargeAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Deliver/expressDelete.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getDeliveryListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCurrentRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/getRate.html', payload);
      if (res.data.data.code === 0) {
        return Promise.resolve(res.data.data.data.rate);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setCurrentRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setRate.html', payload);
      if (res.data.status === 1) {
        const { basicInfo } = context.state;
        basicInfo.data.rate = payload.rate;
        basicInfo.data = [basicInfo.data];
        context.commit('SET_BASIC_INFO', basicInfo);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getShopFriendLinkAsync() {
    try {
      const res = await this.$axios.post('/Link/lists.html');
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addShopFriendLinkAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Link/add.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editShopFriendLinkAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Link/edit.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteShopFriendLinkAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Link/delete.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getShopSeoAsync() {
    try {
      const res = await this.$axios.post('/sy/getSeoPolicy.html');
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setShopSeoAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setShopSeo.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setShippingPolicyAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setShippingPolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setPaymentPolicyAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setPaymentPolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setReturnPolicyAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setReturnPolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 隐私政策设置
   * @date 2022-26-11
   * @param context
   * @param payload
   * @returns {*}
   */
  async setPrivacyPolicyAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setPrivacyPolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取默认政策
   * @date 2022-28-11
   * @param context
   * @param payload
   * @returns {*}
   */
  async getDefaultPolicyAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/getDefaultPolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 服务条款
   * @date 2022-26-11
   * @param context
   * @param payload
   * @returns {*}
   */
  async setServicePolicyAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setServicePolicy.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAllCurrencyAsync(context) {
    try {
      const res = await this.$axios.post('/Rate/getCurrency.html');
      if (res.data.status === 1) {
        context.commit('SET_ALL_CURRENCY', res.data.data);
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getExchangeRateAsync() {
    try {
      const res = await this.$axios.post('/Rate/lists.html');
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addExchangeRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Rate/add.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async editExchangeRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Rate/edit.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setDefaultExchangeRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Rate/setDefault.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async deleteExchangeRateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Rate/delete.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 初始化快递和运费
   * @date 2022-02-08
   * @param context
   * @param payload
   * @returns {*}
   */
  async initRegionAndFeeAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/copyDefaultDeliver.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取邮箱服务商列表
   * @date 2022-02-08
   * @param context
   * @param payload
   * @returns {*}
   */
  async getMailOptionAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/getAbleEmail.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_MAIL_OPTION', res.data.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setMailServiceAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setEmail.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getBasicInfoAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setNotificationAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/setNoticePolicy.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getBasicInfoAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置基本信息
   * @date 2022-14-11
   * @param context
   * @param payload
   * @returns {*}
   */
  async setSystemInfoAsync(context, payload) {
    try {
      const res = await this.$axios.post('/sy/systemSet.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getBasicInfoAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
