<template>
  <v-container fill-height fluid align-start class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      color="secondary darken-3"
      dark
      width="220"
      bottom
    >
      <v-list class="py-0">
        <v-list-item :to="{ name: 'home' }" exact active-class="primary darken-4">
          <v-list-item-icon>
            <v-icon class="mdi-18px"> mdi-home </v-icon>
          </v-list-item-icon>
          <v-list-item-title>首页</v-list-item-title>
        </v-list-item>
        <v-list-group v-for="item in navs" :key="item.text" v-model="item.model" no-action>
          <!-- @click="$router.push(item.url)" -->
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="mdi-18px">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.url"
            active-class="primary darken-4"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- <v-list-item
          :to="{name: 'system_apps'}"
          active-class="primary darken-4"
        >
          <v-list-item-icon>
            <v-icon class="mdi-18px">
              mdi-apps
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>应用市场</v-list-item-title>
        </v-list-item> -->
      </v-list>
      <template v-slot:append>
        <!-- <v-list
          outlined
          dense
        >
          <v-list-item
            :to="{name: 'system_setup'}"
            active-class="primary darken-4"
          >
            <v-list-item-icon>
              <v-icon class="mdi-18px">
                mdi-cog-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>设置</v-list-item-title>
          </v-list-item>
        </v-list> -->
        <div class="pa-4">
          <v-btn block text class="v-btn--active" :to="{ name: 'system_setup' }">
            <v-icon left small> mdi-cog-outline </v-icon> 设置
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="secondary darken-4 elevation-1"
      dark
      clipped-left
      :height="overdue < 31 ? 92 : ''"
    >
      <v-col cols="12">
        <v-row no-gutters style="margin-right: -18px; margin-left: -18px">
          <v-col
            v-if="overdue < 31"
            cols="12"
            style="background-color: #35c08e; height: 44px"
            class="text-center d-flex align-center justify-center px-0 py-2"
          >
            <span v-if="overdue < 0" class="body-2 mr-3">您的会员已过期</span>
            <span v-else-if="overdue >= 0 && overdue < 31" class="body-2 mr-3">
              <!-- 会员截止日期：{{ $store.state.user.period_time | dateTruncate(16) }} -->
              您的会员使用时间剩余{{ overdue + 1 }}天
            </span>
            <v-btn color="white" dark outlined small class="text-body-2" @click="dialog = true">
              <span>续费</span>
            </v-btn>
          </v-col>
          <v-col cols="12" class="d-flex align-center px-2">
            <v-toolbar-title class="d-flex align-center">
              <v-app-bar-nav-icon
                :style="{
                  marginLeft: $vuetify.breakpoint.mobile ? '-10px' : '-5px',
                }"
                @click.stop="drawer = !drawer"
              />
              <span class="title">{{ user.companyName || "九域科技" }}</span>
            </v-toolbar-title>
            <div v-if="!$vuetify.breakpoint.mobile && user" class="ml-12">
              {{ user.mobile }}，欢迎登录后台管理系统!
            </div>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  class="mr-3"
                  icon
                  small
                  v-on="on"
                  @click="$vuetify.rtl = !$vuetify.rtl"
                >
                  <v-icon> {{ $vuetify.rtl ? 'mdi-format-textdirection-l-to-r' : 'mdi-format-textdirection-r-to-l'}} </v-icon>
                </v-btn>
              </template>
              <span>阅读方向</span>
            </v-tooltip>
            <v-btn
              v-if="user && user.isInit === '1'"
              color="warning"
              :small="$vuetify.breakpoint.mobile"
              class="mr-3"
              target="_blank"
              @click="dialogConfirm = true"
            >
              <v-icon v-if="!$vuetify.breakpoint.mobile" left color="white"> mdi-restart </v-icon
              ><span>初始化店铺</span>
            </v-btn>
            <v-btn
              :disabled="!basicInfo.data || (!basicInfo.data.selfUrl && !basicInfo.data.url)"
              color="#35c08e"
              :small="$vuetify.breakpoint.mobile"
              class="mr-3"
              target="_blank"
              :href="
                'http://' + (basicInfo.data.selfUrl ? getSelfUrl(basicInfo.data.selfUrl) : basicInfo.data.url)
              "
            >
              <v-icon v-if="!$vuetify.breakpoint.mobile" left color="white">
                mdi-shopping-outline </v-icon
              ><span>我的店铺</span>
            </v-btn>
            <v-btn color="primary" :small="$vuetify.breakpoint.mobile" @click="logout">
              <v-icon v-if="!$vuetify.breakpoint.mobile" left> mdi-power-standby </v-icon
              ><span>退出登录</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>
    <v-main class="height-100 grey lighten-5" style="overflow-x: hidden">
      <div
        class="d-flex align-center breadcrumbs-container grey lighten-5"
        :class="$vuetify.breakpoint.mobile ? 'pl-3' : 'pl-4 pt-2'"
        :style="{
          left: drawer && !$vuetify.breakpoint.mobile ? '220px' : '0',
          top: overdue < 31 ? '92px' : '64px',
        }"
      >
        <v-btn plain icon style="margin-right: -18px" @click="$router.back()">
          <v-icon color="grey" style="margin-top: -1px"> mdi-chevron-left </v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadCrumbs" class="py-2" />
      </div>
      <v-footer v-if="showFooter" absolute>
        <div class="pt-2 text-center white flex-grow-1">
          <v-btn
            v-if="$route.name === 'service_email_send'"
            depressed
            color="secondary"
            :loading="previewing"
            class="px-12 body-1 mr-2"
            @click="$refs.childView.previewEmail()"
          >
            预览
          </v-btn>
          <v-btn
            v-if="
              user.appIds.includes('17') &&
              ($route.name === 'product_add' ||
                $route.name === 'product_edit' ||
                $route.name === 'system_basic_info')
            "
            depressed
            color="accent"
            :loading="translating"
            class="px-12 body-1 mr-2"
            @click="$refs.childView.translate()"
          >
            翻译
          </v-btn>
          <v-btn
            v-if="disabled"
            depressed
            color="secondary"
            class="px-12 body-1"
            @click="disabled = false"
          >
            编辑
          </v-btn>
          <v-btn
            v-else
            :loading="submitting"
            :disabled="submitting"
            depressed
            color="primary"
            class="px-12 body-1"
            @click="$refs.childView.submitForm()"
          >
            提交
          </v-btn>
        </div>
      </v-footer>
      <transition :name="transitionName">
        <router-view
          ref="childView"
          :key="$route.path"
          class="child-view white pa-4"
          :disabled.sync="disabled"
          @update:scrollTop="scrollTop"
          @update:submitting="submitting = $event"
          @update:previewing="previewing = $event"
          @update:translating="translating = $event"
          @update:show-warning="
            intermediationMsg = $event;
            dialogIntermediation = true;
          "
        />
      </transition>
    </v-main>
    <!-- <v-footer
      app
      inset
      class="body-2 grey--text justify-center align-center py-3"
      elevation="2"
    >
      Copyright © 九域科技, All Rights Reserved
    </v-footer> -->
    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-card-title v-if="overdue >= 0" class="headline deep-orange accent-2 white--text mb-5">
          续费
          <v-spacer />
          <v-btn color="grey lighten-4" icon text :ripple="false" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <account-renew v-if="user" :height="overdue > 0 ? '900' : ''" :fixed-header="overdue > 0" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="headline deep-orange accent-2 white--text">
          确定初始化店铺吗
          <v-spacer />
          <v-btn color="grey lighten-4" icon text :ripple="false" @click="dialogConfirm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          初始化后，现有的测试数据将被清除，包括商品分类，商品列表以及广告图等。
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="yellow darken-3" depressed :loading="initing" @click="initShop">
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInit" max-width="500px">
      <v-card>
        <v-card-title class="headline deep-orange accent-2 white--text">
          店铺操作须知
          <v-spacer />
          <v-btn color="grey lighten-4" icon text :ripple="false" @click="dialogInit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          您当前的店铺处在开通状态，所有添加或修改的数据都将在初始化后删除，若要正式开始您的业务，请先单击右上方<span
            class="error--text text-decoration-underline"
            >”初始化店铺“</span
          >按钮后再开始上传您的数据。
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="yellow darken-3" depressed @click="dialogInit = false"> 确定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogIntermediation" max-width="500px" persistent no-click-animation>
      <v-card>
        <v-card-title class="pr-2">
          <v-spacer />
          <v-btn color="grey" icon @click="dialogIntermediation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-body-1 black--text d-flex align-center">
          <v-icon color="yellow darken-3" class="mr-2" size="24px">
            mdi-information-outline
          </v-icon>
          {{ intermediationMsg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="yellow darken-3"
            depressed
            @click="
              dialogIntermediation = false;
              $refs.childView.submitForm(true);
            "
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import * as R from 'ramda';
import getSelfUrl from '@/plugins/utils';
import AccountRenew from '@/components/AccountRenew.vue';

export default {
  name: 'Home',
  components: {
    AccountRenew,
  },
  data: () => ({
    dialogInit: false,
    dialog: false,
    dialogConfirm: false,
    submitting: false,
    initing: false,
    drawer: null,
    dialogIntermediation: false,
    intermediationMsg: '',
    transitionName: 'v-slide-x-transition',
    renewType: 0,
    showFooter: false,
    disabled: false,
    previewing: false,
    translating: false,
  }),
  computed: {
    ...mapState(['user']),
    ...mapState('system', ['basicInfo']),
    ...mapGetters('system', ['overdue']),
    hasGoodsPromotion() {
      return this.user?.appIds?.includes('8') || false;
    },
    hasOrderPromotion() {
      return this.user?.appIds?.includes('7') || false;
    },
    hasProductComment() {
      return this.user?.appIds?.includes('2') || false;
    },
    hasFreeShippingPromotion() {
      return this.user?.appIds?.includes('12') || false;
    },
    hasEmailPromotion() {
      return this.user?.appIds?.includes('14') || false;
    },
    hasCouponPromotion() {
      return this.user?.appIds?.includes('13') || false;
    },
    navs() {
      return this.$store.state.menus;
    },
    breadCrumbs() {
      return R.prepend(
        {
          text: '首页',
          disabled: false,
          to: { name: 'home' },
          exact: true,
        },
        this.$route.meta.breadCrumbs,
      );
    },
    routerArray() {
      return R.pluck('children', this.$store.state.menus);
    },
    routerNames() {
      return R.map((item) => R.map((v) => R.path(['url', 'name'], v), item), this.routerArray);
    },
  },
  watch: {
    $route(to, from) {
      const index = R.findIndex(R.includes(to.name), this.routerNames);
      if (index >= 0) {
        const obj = this.navs[index];
        obj.model = true;
        this.$set(this.navs, index, obj);
      }
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      if (this.$route.meta.submit) {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }
      this.disabled = false;
    },
    overdue(newValue) {
      if (newValue < 0) {
        this.dialog = true;
      }
    },
  },
  created() {
    if (this.user && this.user.isInit === '1') {
      this.dialogInit = true;
    }
    const index = R.findIndex(R.includes(this.$router.history.current.name), this.routerNames);
    if (index >= 0) {
      const obj = this.navs[index];
      obj.model = true;
      this.$set(this.navs, index, obj);
    }
    if (this.$route.meta.submit) {
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }
    if (this.overdue < 0) {
      this.dialog = true;
    }
  },
  methods: {
    ...mapMutations('system', ['CLEAR_BASIC_INFO']),
    ...mapActions(['initShopAsync', 'loginOutSync']),
    getSelfUrl(v) {
      return getSelfUrl(v);
    },
    logout() {
      this.loginOutSync(this.$route.fullPath);
    },
    scrollTop() {
      this.$refs.childView.$el.scrollTop = 0;
    },
    initShop() {
      this.initing = true;
      this.initShopAsync()
        .then(() => {
          const { user } = this.$store.state;
          user.isInit = '0';
          this.$store.commit('SET_USER', user);
          document.location.reload();
        })
        .finally(() => {
          this.initing = false;
        });
    },
  },
};
</script>

<style lang="scss">
.text-20 {
  font-size: 18px !important;
}
</style>
