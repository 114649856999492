import Login from '../views/Login.vue';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "group-public" */ '../views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: {
      name: 'NotFound',
    },
  },
];
