import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import qs from 'qs';
import router from '@/router';
import state from './root/state';
import getters from './root/getters';
import mutations from './root/mutations';
import actions from './root/actions';
import product from './product/index';
import system from './system/index';
import customer from './customer/index';
import order from './order/index';
import authority from './authority/index';
import warehouse from './warehouse/index';
import information from './information/index';
import marketing from './marketing/index';
import analysis from './analysis/index';
import app from './app/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    information: {
      namespaced: true,
      state: information.state,
      getters: information.getters,
      mutations: information.mutations,
      actions: information.actions,
    },
    product: {
      namespaced: true,
      state: product.state,
      getters: product.getters,
      mutations: product.mutations,
      actions: product.actions,
    },
    order: {
      namespaced: true,
      state: order.state,
      getters: order.getters,
      mutations: order.mutations,
      actions: order.actions,
    },
    system: {
      namespaced: true,
      state: system.state,
      getters: system.getters,
      mutations: system.mutations,
      actions: system.actions,
    },
    analysis: {
      namespaced: true,
      state: analysis.state,
      getters: analysis.getters,
      mutations: analysis.mutations,
      actions: analysis.actions,
    },
    customer: {
      namespaced: true,
      state: customer.state,
      getters: customer.getters,
      mutations: customer.mutations,
      actions: customer.actions,
    },
    authority: {
      namespaced: true,
      state: authority.state,
      getters: authority.getters,
      mutations: authority.mutations,
      actions: authority.actions,
    },
    warehouse: {
      namespaced: true,
      state: warehouse.state,
      getters: warehouse.getters,
      mutations: warehouse.mutations,
      actions: warehouse.actions,
    },
    marketing: {
      namespaced: true,
      state: marketing.state,
      getters: marketing.getters,
      mutations: marketing.mutations,
      actions: marketing.actions,
    },
    app: {
      namespaced: true,
      state: app.state,
      getters: app.getters,
      mutations: app.mutations,
      actions: app.actions,
    },
  },
  state,
  getters,
  mutations,
  actions,
});

// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做某件事
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      if (process.env.NODE_ENV === 'development') {
        console.log(
          `POST ${config.baseURL}${config.url}: ${JSON.stringify(config.data)}`,
        );
      }
      return config;
    }
    if (process.env.NODE_ENV === 'development') {
      console.log(`POST ${config.url}: ${JSON.stringify(config.data)}`);
    }
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    if (res.config.baseURL !== 'https://tiktokwap.oss-us-west-1.aliyuncs.com') {
      // 对响应数据做些事
      if (process.env.NODE_ENV === 'development') {
        console.log(`RETURN ${res.config.url}`, res.data);
      }
      if (!res.data) {
        console.log(123);
        store.commit('TOGGLE_SNACKBAR', {
          type: 'error',
          text: '获取数据失败',
        });
      } else if (res.data.status !== 1) {
        if (!res.data.info) {
          store.commit('TOGGLE_SNACKBAR', {
            type: 'error',
            text: '获取数据失败',
          });
        } else if (res.data.info === '非法访问') {
          if (router.currentRoute.name !== 'login') {
            sessionStorage.setItem('prev', router.currentRoute.fullPath);
          }
          store.commit('SET_DIALOG_OFF', true);
        } else {
          store.commit('TOGGLE_SNACKBAR', {
            type: 'error',
            text: res.data.info,
          });
        }
      }
    }
    return Promise.resolve(res);
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(error);
    }
    if (error.message.includes('timeout')) {
      store.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '连接超时，请检查网络状况并刷新',
      });
    } else if (error.message.includes('404')) {
      store.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '找不到数据接口，请联系管理员',
      });
    } else if (error.message === 'Network Error') {
      store.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '网络错误，请检查并重试',
      });
    } else {
      store.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: error.message,
      });
    }
  },
);

store.$axios = axios;

export default store;
