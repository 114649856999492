<template>
  <div>
    <v-container fluid>
      <v-alert
        v-if="overdueSeconds <= 0"
        type="error"
        class="mb-4"
        dense
        prominent
      >
        <v-row align="center">
          <v-col class="grow"> 您的账号已过期，请续费. </v-col>
          <v-col class="shrink">
            <v-btn text style="font-size: 14px" @click="logout">
              退出登录 >
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-row class="mb-3">
        <v-col cols="12" lg="6" xl="4">
          <div class="input-group align-center">
            <div class="input-group-prepend large text-right">
              <span class="input-group-text">账号类型</span>
            </div>
            <div class="input-group-control">
              <v-card color="grey lighten-4 pa-3">
                {{
                  basicInfo.data.type === "4"
                    ? "零批"
                    : basicInfo.data.type === "1"
                    ? "零售"
                    : "批发"
                }}
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" lg="6" xl="4">
          <div class="input-group align-center">
            <div class="input-group-prepend large text-right">
              <span class="input-group-text">续费金额</span>
            </div>
            <div class="input-group-control font-weight-bold">
              <v-card color="grey lighten-4 pa-3 error--text">
                ￥<span class="text-h5">{{ user.secondPrice }}</span>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-3" align="center">
        <v-col cols="12" lg="6" xl="4">
          <div class="input-group align-center">
            <div class="input-group-prepend large text-right">
              <span class="input-group-text"></span>
            </div>
            <div class="input-group-control">
              <div>
                <a :href="promotionUrl + '/pricing/'" target="_blank"
                  >查看定价计划?</a
                >
              </div>
              <span class="error--text"
                >对续费价格有疑问或需要修改账号版本/类型，请联系我们的业务员。</span
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12" lg="6" xl="4">
          <div class="input-group align-center">
            <div class="input-group-prepend large text-right">
              <span class="input-group-text">到期时间</span>
            </div>
            <div class="input-group-control">
              <v-card color="grey lighten-4 pa-3">
                {{ user.period_time }}
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12" lg="6" xl="4">
          <div class="input-group align-center">
            <div class="input-group-prepend large text-right">
              <span class="input-group-text"></span>
            </div>
            <div class="input-group-control">
              <v-btn
                color="success"
                large
                depressed
                :href="baseUrl + 'o/goPay'"
                >续费</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';

export default {
  name: 'AccountRenew',
  computed: {
    ...mapState('system', ['basicInfo', 'mailOptions']),
    ...mapState(['user']),
    ...mapGetters('system', ['overdueSeconds']),
    promotionUrl() {
      return process.env.VUE_APP_PROMOTION_URL;
    },
    baseUrl() {
      return process.env.VUE_APP_API;
    },
  },
  methods: {
    ...mapActions(['loginOutSync']),
    logout() {
      this.loginOutSync(this.$route.fullPath);
    },
  },
};
</script>
