import * as R from 'ramda';
import { moment } from '@/plugins/moment';

export default {
  useStarRate(state) {
    return ['chicos', 'chicos2', 'st-bags-01', 'default', 'arts', 'arts-ab', 'housewear', 'sport', 'electronics', 'merchandise', 'default-modulized'].includes(state.basicInfo.data.template);
  },
  useBackImg(state) {
    return ['chicos', 'chicos2', 'housewear', 'default-modulized', 'sport'].includes(state.basicInfo.data.template);
  },
  // useNoneUser(state) {
  //   return ['electronics', 'sport', 'housewear', 'default-modulized', 'sport', 'merchandise'].includes(state.basicInfo.data.template);
  // },
  useProductDiscount(state) {
    return ['chicos', 'chicos2'].includes(state.basicInfo.data.template);
  },
  usePriceStatus(state, getters, rootState) {
    // 启用价格区间
    // return state.basicInfo.data.type !== '1' && rootState.user.appIds.includes('21');
    return rootState.user.appIds.includes('21');
  },
  isWholesaleTemplate(state) {
    return ['wholesale'].includes(state.basicInfo.data.template);
  },
  // isTemplateModulized(state) {
  //   return !['chicos', 'chicos2', 'chicos2'].includes(state.basicInfo.data.template);
  // },
  countryGetter: (state) => (region) => R.map((item) => {
    item.name = `${item.dnamesCn}(${item.dnames})`;
    if (item.children) {
      item.children = R.map((subitem) => {
        subitem.name = `${subitem.dnamesCn}(${subitem.dnames})`;
        return subitem;
      }, item.children);
    }
    return item;
  }, R.filter((item) => item.region === region, state.countryList.data)),
  countryTree(state, getters) {
    return [
      {
        name: '美洲(Americas)',
        numericCode: '-1',
        id: '-1',
        children: getters.countryGetter('Americas'),
      },
      {
        name: '欧洲(Europe)',
        numericCode: '-2',
        id: '-2',
        children: getters.countryGetter('Europe'),
      },
      {
        name: '亚洲(Asia)',
        numericCode: '-3',
        id: '-3',
        children: getters.countryGetter('Asia'),
      },
      {
        name: '非洲(Africa)',
        numericCode: '-4',
        id: '-4',
        children: getters.countryGetter('Africa'),
      },
      {
        name: '南极洲(Antarctic)',
        numericCode: '-5',
        id: '-5',
        children: getters.countryGetter('Antarctic'),
      },
      {
        name: '南冰洋(Antarctic Ocean)',
        numericCode: '-6',
        id: '-6',
        children: getters.countryGetter('Antarctic Ocean'),
      },
      {
        name: '大洋洲(Oceania)',
        numericCode: '-7',
        id: '-7',
        children: getters.countryGetter('Oceania'),
      },
      {
        name: '极地(Polar)',
        numericCode: '-8',
        id: '-8',
        children: getters.countryGetter('Polar'),
      },
    ];
  },
  overdue(state) {
    if (state.basicInfo.data) {
      return state.basicInfo.data.period_time
      && moment(state.basicInfo.data.period_time).diff(moment(), 'days');
    }
    return 1000;
  },
  overdueSeconds(state) {
    if (state.basicInfo.data) {
      return state.basicInfo.data.period_time
      && moment(state.basicInfo.data.period_time).diff(moment(), 'seconds');
    }
    return 1000 * 3600 * 24;
  },
};
