import Cookies from 'js-cookie';
// import * as R from 'ramda';

import {
  SET_USER,
  CLEAR_USER,
  TOGGLE_SNACKBAR,
  START_LOADING,
  END_LOADING,
  SET_GLOBAL_CUSTOMER,
  CLOSE_SNACKBAR,
  SET_DIALOG_OFF,
} from '../mutation-types';

export default {
  SET_MENUS(state, payload) {
    state.menus = payload;
  },
  [SET_GLOBAL_CUSTOMER](state, payload) {
    state.customerId = payload;
  },
  [START_LOADING](state) {
    state.loading = true;
  },
  [END_LOADING](state) {
    state.loading = false;
  },
  [SET_USER](state, payload) {
    Cookies.set('user', JSON.stringify(payload));
    state.user = payload;
  },
  [CLEAR_USER](state, payload) {
    sessionStorage.setItem('prev', payload);
    Cookies.remove('user');
    window.location.href = '/';
  },
  [TOGGLE_SNACKBAR](state, payload) {
    if (payload.type === 'error') {
      state.snackbarColor = 'error';
      state.snackbarIcon = 'mdi-alert-circle';
    } else {
      state.snackbarColor = 'success';
      state.snackbarIcon = 'mdi-check';
    }
    state.snackbarText = payload.text;
    state.snackbarShow = true;
  },
  [CLOSE_SNACKBAR](state) {
    state.snackbarShow = false;
  },
  [SET_DIALOG_OFF](state, payload) {
    state.dialogOff = payload;
  },
  SET_VIEWER(state, payload) {
    state.viewer = payload;
  },
};
