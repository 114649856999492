import Vue from 'vue';
import md5 from 'md5';
import Cookies from 'js-cookie';
import VueViewer from 'v-viewer';
import createRouter from './router/createRouter';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import plugins from './plugins/plugins';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'viewerjs/dist/viewer.css';

Vue.config.productionTip = false;
Vue.use(plugins);
Vue.use(VueViewer);

const bootstrap = async () => {
  let router = createRouter();
  // 处理初始化时的用户信息
  let user = Cookies.get('user');
  const mobile = Cookies.get('mobile', { domain: process.env.VUE_APP_DOMAIN });
  const password = Cookies.get('password', { domain: process.env.VUE_APP_DOMAIN });
  if (mobile && password) {
    if (user) {
      Cookies.remove('user');
    }
    await store.dispatch('loginInSync', {
      password: md5(password),
      username: mobile,
    }).then(() => {
      router = createRouter(store.state.system.basicInfo.data.type, store.state.user.appIds);
      Cookies.remove('mobile', { domain: process.env.VUE_APP_DOMAIN });
      Cookies.remove('password', { domain: process.env.VUE_APP_DOMAIN });
    });
  } else if (user) {
    user = JSON.parse(user);
    if (user) {
      store.commit('SET_USER', user);
      await store.dispatch('system/getBasicInfoAsync').then(() => {
        router = createRouter(store.state.system.basicInfo.data.type, user.appIds);
      }).catch(() => {
        store.commit('SET_DIALOG_OFF', true);
      });
    } else {
      store.commit('SET_USER', null);
    }
  }

  router.beforeEach((to, from, next) => {
    if (store.state.viewer) {
      // 切换页面时隐藏viewer插件
      store.state.viewer.hide();
    }
    if (to.name === 'login') {
      next();
    }
    if (store.state.user) {
      if (to.meta.required) {
        store.dispatch('checkAuthorityAsync', { rightId: to.meta.id }).then((res) => {
          if (res) {
            next();
          } else {
            store.commit('TOGGLE_SNACKBAR', {
              type: 'error',
              text: '您不具备目标页面权限，请联系管理员',
            });
            sessionStorage.removeItem('prev');
          }
        });
      } else {
        next();
      }
    } else {
      next({ name: 'login' });
    }
  });

  new Vue({
    router,
    store,
    vuetify,
    mounted() {
      document.getElementById('loading').remove();
    },
    render: (h) => h(App),
  }).$mount('#app');
};

bootstrap();
