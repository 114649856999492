import * as R from 'ramda';

export default {
  // 应用列表
  async getAppListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/app/appList.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_APP_LIST', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 我的应用
  async getAppInstalledAsync(context, payload) {
    try {
      const res = await this.$axios.post('/app/myApp.html', payload);
      if (res.data.status === 1) {
        context.commit('SET_APP_INSTALLED', res.data.data);
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 添加应用
  async addAppAsync(context, payload) {
    try {
      const res = await this.$axios.post('/app/addApp.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getAppListAsync');
        await context.dispatch('getAppInstalledAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 删除应用
  async deleteAppAsync(context, payload) {
    try {
      const res = await this.$axios.post('/app/deleteApp.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('getAppInstalledAsync');
        context.dispatch('getAppListAsync');
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 续费应用
   * @date 2022-30-04
   * @param context
   * @param payload
   * @returns {*}
   * @arguments appPayId   renewType   sellerId
   */
  async renewAppCallbackAsync(context, payload) {
    try {
      const res = await this.$axios.post('/o/doAppRenew.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 邮件设置预览
   * @date 2022-25-08
   * @param context
   * @param payload
   * @returns {*}
   */
  async previewEmailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Email/viewEmailGoods.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.htmlData);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 发送邮件
   * @date 2022-26-08
   * @param context
   * @param payload
   * @returns {*}
   */
  async sendEmailServiceAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Email/sendGoodsByEmail.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取邮件列表
   * @date 2022-30-08
   * @param context
   * @param payload
   * @returns {*}
   */
  async getEmailListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Email/emailLists.html', R.mergeRight({
        pageSize: process.env.VUE_APP_PAGESIZE,
        timeLimit: context.state.emailList.data.timeLimit,
        p: context.state.emailList.data.p,
      }, payload));
      if (res.data.status === 1) {
        context.commit('SET_MAIL_OPTION', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async resendEmailAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Email/sendEmailAgain.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取自定义商品标签内容
   * @date 2022-16-09
   * @param context
   * @param payload
   * @returns {*}
   */
  async getCustomLabelAsync(context, payload) {
    try {
      const res = await this.$axios.post('/app/getGoodTagSet.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置自定义标签
   * @date 2022-16-09
   * @param context
   * @param payload
   * @returns {*}
   */
  async setCustomLabelAsync(context, payload) {
    try {
      const res = await this.$axios.post('/app/setGoodsTag.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 翻译内容
   * @date 2022-07-11
   * @param context  transType  1: 分类  2：属性  3：规格  4：商品  5：系统
   * @param payload
   * @returns {*}
   */
  async translateAsync(context, payload) {
    try {
      const res = await this.$axios.post('/Tools/getTranslate.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 设置加密商品密码
   * @date 2023-09-02
   * @param context password 必须4位以上
   * @param payload
   * @returns {*}
   */
  async setProductPwdAsync(context, payload) {
    try {
      const res = await this.$axios.post('/system/setGoodsPassword.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('system/getBasicInfoAsync', null, { root: true });
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 获取加密商品申请人列表
   * @date 2023-10-02
   * @param context
   * @param payload
   * @returns {*}
   */
  async getApplyListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/applyList.html', payload);
      if (res.data.status === 1) {
        await context.dispatch('system/getBasicInfoAsync', null, { root: true });
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 发送密码邮件
   * @date 2023-13-02
   * @param context
   * @param payload
   * @returns {*}
   */
  async applyActionAsync(context, payload) {
    try {
      const res = await this.$axios.post('/g/applyAction.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 跨境商品列表
   * @date 2023-31-03
   * @param context
   * @param payload
   * @returns {*}
   */
  async getCloudProductsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/SystemGoods/lists.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 下载跨境商品
   * @date 2023-31-03
   * @param context
   * @param payload
   * @returns {*}
   */
  async downloadCloudProductsAsync(context, payload) {
    try {
      const res = await this.$axios.post('/SystemGoods/cloneGoods.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
