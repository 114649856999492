export default {
  customerList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
  customerFeedbackList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
  customerNewsLetterList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
  customerListForSearch: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: null,
      p: 1,
    },
  },
  customerListForSelect: {
    status: 0,
    data: [],
  },
  // customerGradeList: {
  //   status: 0,
  //   data: [],
  // },
};
