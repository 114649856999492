import * as R from 'ramda';

export default {
  // 添加客户
  async addOrEditCustomerAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: payload.edit ? 263 : 259 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/buyer/add_save_buyer.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getCustomerListAsync', { timeLimit: null });
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 删除客户
  async deleteCustomerAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 262 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/buyer/del_buyer.html', payload);
        if (res.data.status === 1) {
          // await context.dispatch('getCustomerListAsync', { timeLimit: null });
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 客户收货地址列表,传buyerId
  async getCustomerShippingListAsync(context, payload) {
    try {
      const res = await this.$axios.post('/buyer/area_lists.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 传buyerId,buyerUid
  async addOrEditCustomerShippingAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: payload.edit ? 351 : 350 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/buyer/do_addr.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 传id
  async deleteCustomerShippingAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 352 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/buyer/del_addr.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 传id
  async setDefaultCustomerShippingAsync(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 353 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/buyer/set_default.html', payload);
        if (res.data.status === 1) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 客户列表
  async getCustomerListAsync(context, payload) {
    const params = payload.local ? payload : R.mergeRight({
      pageSize: process.env.VUE_APP_PAGESIZE,
      timeLimit: context.state.customerList.data.timeLimit,
      p: context.state.customerList.data.p,
    }, payload);
    try {
      const res = await this.$axios.post('/buyer/buyer_list.html', params);
      if (res.data.status === 1) {
        if (!payload.local) {
          context.commit('SET_CUSTOMER_LIST', res.data);
        }
        return Promise.resolve(res.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 客户反馈列表
  async getCustomerFeedbackListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/ot/feedback_list.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.customerFeedbackList.data.timeLimit,
            p: context.state.customerFeedbackList.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_CUSTOMER_FEEDBACK_LIST', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // 客户订阅列表
  async getCustomerNewsLetterListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/ot/email_list.html',
        R.mergeRight(
          {
            pageSize: process.env.VUE_APP_PAGESIZE,
            timeLimit: context.state.customerNewsLetterList.data.timeLimit,
            p: context.state.customerNewsLetterList.data.p,
          },
          payload,
        ),
      );
      if (res.data.status === 1) {
        context.commit('SET_CUSTOMER_NEWS_LETTER_LIST', res.data);
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description 客户询盘列表
   * @date 2022-11-03
   * @param context
   * @param payload
   * @returns {*}
   */
  async getInquiryListAsync(context, payload) {
    try {
      const res = await this.$axios.post(
        '/ot/inquiry_list.html',
        payload,
      );
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // async getCustomerListForSearchAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/buyer/buyer_list.html', R.mergeRight({
  //       pageSize: process.env.VUE_APP_PAGESIZE,
  //       timeLimit: context.state.customerListForSearch.data.timeLimit,
  //       p: context.state.customerListForSearch.data.p,
  //     }, payload));
  //     if (res.data.status === 1) {
  //       context.commit('SET_CUSTOMER_FOR_SEARCH_LIST', res.data);
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  // async getCustomerListForSelectAsync(context, payload) {
  //   try {
  //     const res = await this.$axios.post('/buyer/buyer_list.html', payload);
  //     if (res.data.status === 1) {
  //       context.commit('SET_CUSTOMER_FOR_SELECT_LIST', res.data);
  //       return Promise.resolve(res.data.status);
  //     }
  //     return Promise.reject(new Error(res.data.info));
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },
  async setCustomerGrade(context, payload) {
    const right = await context.dispatch('checkAuthorityAsync', { rightId: 260 }, { root: true });
    if (right) {
      try {
        const res = await this.$axios.post('/buyer/do_buyer_level.html', payload);
        if (res.data.status === 1) {
          await context.dispatch('getCustomerListAsync');
          return Promise.resolve(res.data.status);
        }
        return Promise.reject(new Error(res.data.info));
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      context.commit('TOGGLE_SNACKBAR', {
        type: 'error',
        text: '您没有该操作的权限，请联系管理员',
      }, { root: true });
      return Promise.reject(new Error('您没有该操作的权限，请联系管理员'));
    }
  },
  // 验证客户手机
  async checkCustomerMobileAsync(context, payload) {
    try {
      const res = await this.$axios.post('/user/checkMobile.html', payload);
      if (res.data.status === 1) {
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  /**
   * @description id replyContent:回复内容  replyType：1: 站内回复  2：邮件回复  3：站内且邮件回复
   * @date 2022-15-10
   * @param context
   * @param payload
   * @returns {*}
   */
  async replyInquiryAsync(context, payload) {
    try {
      const res = await this.$axios({
        url: '/ot/reply.html',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: payload,
      });
      if (res.data.status === 1) {
        return Promise.resolve(res.data.status);
      }
      return Promise.reject(new Error(res.data.info));
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
