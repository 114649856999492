import * as R from 'ramda';
import {
  SET_PRODUCT_CATEGORY,
  SET_PRODUCT_BRAND,
  SET_PRODUCT_UNITS,
  SET_PRODUCT_MODELS,
  SET_PRODUCT_DETAIL_MODELS,
  SET_PRODUCT_LIST,
  SET_ONLINE_CATE,
  SET_PRODUCT_LIST_FOR_SELECT,
  SET_PRODUCT_HOT_SEARCH,
  SET_DRAFT_LIST,
} from '../mutation-types';

export default {
  SET_SYSTEM_COMMENT_USER(state, payload) {
    state.comment.user = payload;
  },
  SET_SYSTEM_COMMENT_CONTENT(state, payload) {
    state.comment.content = payload;
  },
  [SET_PRODUCT_LIST](state, payload) {
    state.productList.data = payload;
    state.productList.status = 1;
  },
  [SET_DRAFT_LIST](state, payload) {
    state.draftList.data = payload;
    state.draftList.status = 1;
  },
  [SET_PRODUCT_HOT_SEARCH](state, payload) {
    state.productHotSearch.data = payload;
    state.productHotSearch.status = 1;
  },
  [SET_PRODUCT_CATEGORY](state, payload) {
    state.productCategory.data.items = payload;
    state.productCategory.status = 1;
  },
  [SET_PRODUCT_BRAND](state, payload) {
    state.productBrand.data = payload;
    state.productBrand.status = 1;
  },
  [SET_PRODUCT_UNITS](state, payload) {
    state.productUnits.data = payload;
    state.productUnits.status = 1;
  },
  [SET_PRODUCT_MODELS](state, payload) {
    state.productModels.data = payload;
    state.productModels.status = 1;
  },
  [SET_PRODUCT_DETAIL_MODELS](state, payload) {
    state.productDetailModels.data = payload;
    state.productDetailModels.status = 1;
  },
  [SET_ONLINE_CATE](state, payload) {
    state.onlineCate.data = payload;
    state.onlineCate.status = 1;
  },
  [SET_PRODUCT_LIST_FOR_SELECT](state, payload) {
    payload.items = R.map((item) => {
      item.buNumber = 0;
      return item;
    }, payload.items);
    state.productListForSelect.data = payload;
    state.productListForSelect.status = 1;
  },
};
