export default {
  user: null,
  loading: false,
  snackbarColor: 'success',
  snackbarShow: false,
  snackbarText: '',
  snackbarIcon: '',
  customerId: '',
  productsAddedOrChanged: false,
  dialogOff: false,
  viewer: null,
  menus: [],
};
