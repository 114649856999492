export default {
  productList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: 100,
      p: 1,
    },
  },
  draftList: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: 100,
      p: 1,
    },
  },
  productCategory: {
    status: 0,
    data: {
      items: [],
    },
  },
  onlineCate: {
    status: 0,
    data: {
      items: [],
    },
  },
  productBrand: {
    status: 0,
    data: {
      items: [],
    },
  },
  productUnits: {
    status: 0,
    data: [],
  },
  productModels: {
    status: 0,
    data: [],
  },
  productHotSearch: {
    status: 0,
    data: [],
  },
  productDetailModels: {
    status: 0,
    data: [],
  },
  productListForSelect: {
    status: 0,
    data: {
      items: [],
      timeLimit: null,
      totalItem: 100,
      p: 1,
    },
  },
  comment: {
    content: {
      status: 0,
      data: [],
    },
    user: {
      status: 0,
      data: [],
    },
  },
};
