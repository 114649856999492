<template>
  <div>
    <v-form ref="form" v-model="valid">
      <div v-if="$vuetify.breakpoint.mobile" class="pa-4">
        <v-slide-x-transition hide-on-leave>
        <div v-if="active">
        <div class="font-weight-bold my-4" style="font-size: 24px">欢迎登录义数管理后台</div>
          <v-text-field
            v-model="username"
            :rules="userNameRules"
            label="用户名/手机号码"
            prepend-inner-icon="mdi-account-box"
            required
            clearable
            class="mb-6"
          />
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="密码"
            :type="showPassword ? 'text' : 'password'"
            prepend-inner-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            clearable
            class="mb-6"
            required
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="verify"
            :rules="verifyRules"
            label="验证码"
            prepend-inner-icon="mdi-shield-lock"
            clearable
            class="mb-6"
            required
            lazy-validation
          >
            <template v-slot:append>
              <v-btn
                plain
                text
                style="cursor: pointer; margin-top: -12px"
                class="pa-0 v-btn--active"
                @click="refreshCode"
              >
                <identify :identify-code="verifyCode" :content-width="112" :content-height="36" />
              </v-btn>
            </template>
          </v-text-field>
          <v-btn
            color="#4bbaaa"
            :dark="!loading"
            block
            rounded
            style="font-size: 18px"
            class="mt-8"
            x-large
            :loading="loading"
            @click="loginIn"
          >
            登录
          </v-btn>
          <div class="mt-3 text-center">
            <v-btn plain large @click="active = false">忘记密码?</v-btn>
          </div>
        </div>
        </v-slide-x-transition>
        <v-slide-x-transition hide-on-leave>
          <div v-if="!active">
            <div class="font-weight-bold my-4" style="font-size: 24px">
              <v-btn plain icon color="dark" small class="mr-3" @click="active = true"
                    ><v-icon>mdi-chevron-left mdi-24px</v-icon></v-btn
                  >
              忘记密码
            </div>
            <v-form ref="formReset" v-model="valid">
              <v-text-field
                v-model="mobile"
                :rules="mobileRules"
                label="手机号码"
                prepend-inner-icon="mdi-cellphone"
                required
                clearable
                class="mb-6"
              />
              <v-text-field
                v-model="code"
                :rules="verifyRules"
                class="mb-6"
                label="验证码"
                prepend-inner-icon="mdi-shield-lock"
                clearable
                required
                lazy-validation
              >
                <template v-slot:append>
                  <v-btn
                    color="#4bbaaa"
                    :disabled="disabledCode"
                    style="margin-top:-8px"
                    text
                    @click="getCode"
                  >
                    {{ time }}
                  </v-btn>
                </template>
              </v-text-field>
              <v-text-field
                v-model="passwords"
                :rules="passwordRules"
                label="密码"
                class="mb-6"
                :type="showPassword ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                clearable
                required
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                v-model="checkPasswords"
                :rules="checkPasswordsRules()"
                label="确认密码"
                class="mb-6"
                :type="showCheckPassword ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                :append-icon="showCheckPassword ? 'mdi-eye-off' : 'mdi-eye'"
                clearable
                required
                @click:append="showCheckPassword = !showCheckPassword"
              />
              <v-btn
                color="#4bbaaa"
                block
                rounded
                :dark="!submitting"
                style="font-size: 18px"
                class="mb-3"
                x-large
                :loading="submitting"
                @click="resetPassword"
              >
                提交
              </v-btn>
            </v-form>
          </div>
        </v-slide-x-transition>
      </div>
      <v-container
        v-else
        fluid
        class="bg-login white"
        style="overflow-y: scroll; position: absolute; top: 0; left: 0; right: 0; bottom: 0"
        :style="`background-image: url(${require('@/assets/bg-login.jpg')})`"
      >
        <v-row class="mt-4 mt-md-12 pt-md-12">
          <v-col md="6" lg="4" xl="3" offset-lg="6" class="px-8">
            <img :src="require('@/assets/logo.png')" alt="" srcset="" class="mb-4" />
          </v-col>
          <v-slide-x-transition hide-on-leave>
            <v-col v-if="active" md="6" lg="4" xl="3" offset-lg="6" class="px-8 p-md-3">
              <v-card class="mb-12" elevation="5" rounded="lg">
                <v-card-title
                  class="pa-6 white--text"
                  style="background-color: #4bbaaa; font-size: 24px"
                >
                  欢迎登录管理后台
                </v-card-title>
                <v-card-text class="pa-8">
                  <v-text-field
                    v-model="username"
                    :rules="userNameRules"
                    label="用户名/手机号码"
                    prepend-inner-icon="mdi-account-box"
                    required
                    clearable
                    outlined
                    class="mb-6"
                  />
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="密码"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    clearable
                    outlined
                    required
                    class="mb-6"
                    @click:append="showPassword = !showPassword"
                  />
                  <v-text-field
                    v-model="verify"
                    :rules="verifyRules"
                    label="验证码"
                    prepend-inner-icon="mdi-shield-lock"
                    clearable
                    outlined
                    required
                    class="mb-6"
                    lazy-validation
                  >
                    <template v-slot:append>
                      <v-btn
                        plain
                        text
                        style="cursor: pointer; margin-top: -7px"
                        class="pa-0 v-btn--active"
                        @click="refreshCode"
                      >
                        <identify
                          :identify-code="verifyCode"
                          :content-width="112"
                          :content-height="36"
                        />
                      </v-btn>
                    </template>
                  </v-text-field>
                  <v-btn
                    color="#4bbaaa"
                    :dark="!loading"
                    block
                    rounded
                    style="font-size: 18px"
                    x-large
                    :loading="loading"
                    @click="loginIn"
                  >
                    登录
                  </v-btn>
                  <div class="mt-3 text-right">
                    <v-btn plain large @click="active = false">忘记密码?</v-btn>
                  </div>
                  <!-- <v-checkbox
                    v-model="sevenDayLasting"
                    style="margin-top:-10px"
                    label="七天免登录"
                  /> -->

                  <!-- <v-btn
                    color="secondary"
                    large
                    @click="reset"
                  >
                    重置
                  </v-btn> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-slide-x-transition>
          <v-slide-x-reverse-transition hide-on-leave>
            <v-col v-if="!active" md="6" lg="4" xl="3" offset-lg="6" class="px-8 p-md-3">
              <v-card class="mb-12" elevation="5" rounded="lg">
                <v-card-title
                  class="pa-6 white--text"
                  style="background-color: #4bbaaa; font-size: 24px"
                >
                  <v-btn plain icon color="white" small class="mr-3" @click="active = true"
                    ><v-icon>mdi-chevron-left mdi-24px</v-icon></v-btn
                  >忘记密码
                </v-card-title>
                <v-card-text class="pa-8">
                  <v-form ref="formReset" v-model="valid">
                    <v-text-field
                      v-model="mobile"
                      :rules="mobileRules"
                      label="手机号码"
                      prepend-inner-icon="mdi-cellphone"
                      required
                      clearable
                      outlined
                      class="mb-6"
                    />
                    <v-text-field
                      v-model="code"
                      :rules="verifyRules"
                      class="mb-6"
                      label="验证码"
                      prepend-inner-icon="mdi-shield-lock"
                      clearable
                      outlined
                      required
                      lazy-validation
                    >
                      <template v-slot:append>
                        <v-btn
                          color="#4bbaaa"
                          :disabled="disabledCode"
                          style="margin-top:-8px"
                          text
                          @click="getCode"
                        >
                          {{ time }}
                        </v-btn>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-model="passwords"
                      :rules="passwordRules"
                      label="新密码"
                      class="mb-6"
                      :type="showPassword ? 'text' : 'password'"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      clearable
                      outlined
                      required
                      @click:append="showPassword = !showPassword"
                    />
                    <v-text-field
                      v-model="checkPasswords"
                      :rules="checkPasswordsRules()"
                      label="确认密码"
                      class="mb-6"
                      outlined
                      :type="showCheckPassword ? 'text' : 'password'"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showCheckPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      clearable
                      required
                      @click:append="showCheckPassword = !showCheckPassword"
                    />
                    <v-btn
                      color="#4bbaaa"
                      block
                      rounded
                      :dark="!submitting"
                      style="font-size: 18px"
                      class="mb-3"
                      x-large
                      :loading="submitting"
                      @click="resetPassword"
                    >
                      提交
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-slide-x-reverse-transition>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="-1" color="orange darken-2">
      如果您感觉手机端操作不方便，请在电脑端登录，登录地址<span class="text-primary">{{
        managerUrl
      }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import md5 from 'md5';
import Identify from '@/components/Identify.vue';

export default {
  name: 'Login',
  components: { Identify },
  data() {
    return {
      active: true,
      code: '',
      password: '',
      showPassword: false,
      showCheckPassword: false,
      username: '',
      passwords: '',
      checkPasswords: '',
      sevenDayLasting: null,
      loading: false,
      verify: '',
      verifyCode: '',
      valid: true,
      currentTime: 60,
      time: '获取验证码',
      timer: null,
      mobile: '',
      disabledCode: false,
      submitting: false,
      userNameRules: [(v) => !!v || '请填写用户名...'],
      passwordRules: [(v) => !!v || '请填写密码...'],
      verifyRules: [(v) => !!v || '请填写验证码...'],
      mobileRules: [
        (v) => !!v || '请输入联系电话...',
        (v) => /^1[3456789]\d{9}$/.test(v) || '手机号码格式有误',
      ],
      codeError: false,
      codeErrorMsg: '验证码不正确',
      snackbar: false,
    };
  },
  computed: {
    ...mapState(['user']),
    managerUrl() {
      return process.env.VUE_APP_MANAGER_URL;
    },
  },
  created() {
    this.$store.commit('START_LOADING');
    this.snackbar = this.$vuetify.breakpoint.mobile;
    this.getVerifyCode();
  },
  methods: {
    ...mapActions(['loginInSync', 'getVerifyCodeSync', 'getCodeAsync', 'registerAsync', 'resetPasswordAsync']),
    checkPasswordsRules() {
      return [
        (v) => !!v || '请填写确认密码',
        (v) => v === this.passwords || '两次输入密码不同',
      ];
    },
    loginIn() {
      if (this.$refs.form.validate()) {
        if (this.verifyCode !== this.verify) {
          this.$store.commit('TOGGLE_SNACKBAR', {
            type: 'error',
            text: '验证码不正确',
          });
          return;
        }
        this.loading = true;
        this.loginInSync({
          password: md5(this.password),
          username: this.username,
          verify: this.verify,
        })
          .then((res) => {
            if (res === 1) {
              const prev = sessionStorage.getItem('prev') || '/';
              // this.$router.replace({ path: prev });
              window.location = prev;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getCode() {
      if (!this.mobile) {
        this.$store.commit('TOGGLE_SNACKBAR', {
          type: 'error',
          text: '请输入手机号',
        });
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$store.commit('TOGGLE_SNACKBAR', {
          type: 'error',
          text: '请输入正确的手机号码',
        });
        return;
      }
      this.getCodeAsync({ mobile: this.mobile }).then(() => {
        this.$store.commit('TOGGLE_SNACKBAR', {
          type: 'success',
          text: '验证码发送成功',
        });
        this.time = `${this.currentTime}秒后重新获取`;
        this.disabledCode = true;
        this.timer = setInterval(() => {
          let { currentTime } = this;
          currentTime -= 1;
          this.time = `${currentTime}秒后重新获取`;
          this.currentTime = currentTime;
          if (currentTime === 0) {
            // 读秒结束 清空计时器
            clearInterval(this.timer);
            this.time = '重新获取';
            this.disabledCode = false;
            this.currentTime = 60;
          }
        }, 1000);
      });
    },
    register() {
      if (this.$refs.formReg.validate()) {
        this.submitting = true;
        const params = {
          mobile: this.mobile,
          code: this.code,
          passwords: md5(this.passwords),
        };
        this.registerAsync(params)
          .then(() => {
            this.$store.commit('TOGGLE_SNACKBAR', {
              type: 'success',
              text: '注册成功',
            });
            this.active = true;
            this.username = this.mobile;
            this.password = this.passwords;
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    // reset() {
    //   this.$refs.form.reset();
    // },
    // resetValidation() {
    //   this.$refs.form.resetValidation();
    // },
    refreshCode() {
      this.getVerifyCode();
    },
    getVerifyCode() {
      this.getVerifyCodeSync()
        .then((res) => {
          this.verifyCode = `${res}`;
        })
        .finally(() => {
          this.$store.commit('END_LOADING');
        });
    },
    resetPassword() {
      if (this.$refs.formReset.validate()) {
        this.submitting = true;
        this.resetPasswordAsync({
          code: this.code,
          mobile: this.mobile,
          passwords: md5(this.passwords),
        }).then(() => {
          this.$store.commit('TOGGLE_SNACKBAR', {
            type: 'success',
            text: '密码重置成功',
          });
          this.active = true;
        }).finally(() => {
          this.submitting = false;
        });
      }
    },
  },
};
</script>
